body {
    /* Accent */

    --accent: #F17460;
    --accent_secondary: rgba(241, 116, 96, 0.12);
    --accent_tertiary: rgba(241, 116, 96, 0.08);

    /* Status */

    --negative: #e64646;
    --negative_secondary: #faebeb;

    /* Text */

    --text_primary: #000000;
    --text_secondary: #676767;
    --text_tertiary: #989898;
    --text_overlay: #FFFFFF;

    /* Background */

    --background_primary: #FFFFFF;
    --background_secondary: #F2F2F2;
    --background_tertiary: rgba(0, 0, 0, 0.04);
    --background_overlay: rgba(0, 0, 0, 0.35);
    --background_active: rgba(0, 16, 61, .08);
    --background_hover: rgba(0, 16, 61, .04);

    /* Icon */

    --icon_primary: var(--accent);
    --icon_secondary: #B9B9B9;
    --icon_tertiary: rgba(0, 0, 0, 0.08);

    /* Border */

    --border_primary: #D6D6D6;
    --border_secondary: rgba(0, 0, 0, 0.08);

    /* Fonts */

    --font_family_base: "Inter", sans-serif;
    --font_family_accent: "Bellota", serif;

    /* Common */

    --horizontal_padding: 16px;
    --pageHeader_height: 56px;
}

@media screen and (max-width: 1199px) {
    body {
        --pageHeader_height: 44px;
    }
}