.SimpleCell {
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px var(--horizontal_padding);
    transition: background-color 150ms ease-out;
}

.SimpleCell:hover {
    cursor: pointer;
    background-color: var(--background_hover);
}

.SimpleCell__text {
    font-family: var(--font_family_base);
    font-weight: 400;
}

.SimpleCell__after {
    display: flex;
}

.SimpleCell:active {
    background-color: var(--background_active);
}