@import "./fonts/index.css";
@import "./variables.css";
@import "./reset.css";

html, body, #root, main {
    min-height: 100%;
    height: 100%;
}

body {
    font-family: var(--font_family_base);
    font-size: 16px;
    line-height: 1.25em;
    color: var(--text_secondary);
}

a {
    color: var(--accent);
}

.PageLayout {
    width: 100%;
    min-height: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background_secondary);
    padding-top: var(--pageHeader_height);
}

.PageLayout__in {
    width: 100%;
}

.accent {
    color: var(--accent);
    font-weight: 500;
}

@media screen and (max-width: 767px) {
    .PageLayout {
        align-items: flex-start;
        padding-top: calc(var(--pageHeader_height) + 8px);
    }

    .PageLayout__in {
        min-height: 100%;
        height: 100%;
        background-color: var(--background_primary);
    }
}