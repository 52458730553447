.Select {
    cursor: pointer;
}

.select_el {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
}

.select_el::-ms-expand {
    display: none;
}

.select_el {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-grow: 1;
    padding: 0 12px;
}

.FormField.Select > .select_el:focus ~ .FormField__after {
    transform: rotate(180deg);
}

.Select--empty {
    color: var(--text_tertiary);
}