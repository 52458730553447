.Group {
    padding: 28px;
    border-radius: 28px;
    background: var(--background_primary);
}

@media screen and (max-width: 767px) {
    .Group {
        padding: 20px var(--horizontal_padding);
        border-radius: 0;
    }
}