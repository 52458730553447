.formDescription {
    display: flex;
    color: var(--text_tertiary);
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: 16px 0;
}

.formDescription__line {
    width: 100%;
    border: .5px solid var(--border_primary);
}

.VkIdButton {
    background: #2787F5;
}