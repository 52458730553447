.FormField {
    position: relative;
    border-radius: 12px;
    height: 44px;
    width: 100%;
    color: var(--text_primary);
    display: flex;
    align-items: center;
}

.FormField > * {
    border-radius: inherit;
    z-index: 1;
}

.FormField__border {
    z-index: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
}

.FormField__status-default .FormField__border {
    border-color: var(--border_primary);
}

.FormField__status-error .FormField__border {
    border-color: var(--negative);
    background-color: var(--negative_secondary);
}

.FormField__status-error .FormItem__message {
    color: var(--negative);
}

.FormField *:focus ~ .FormField__border, .FormField *:active ~ .FormField__border {
    border-color: var(--accent);
}

.FormField__after {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    color: var(--icon_secondary);
}

.FormField__after > .IconButton {
    width: 44px;
    height: 44px;
    color: inherit;
}