.ModalPageHeader {
    min-height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalPageHeader__before, .ModalPageHeader__after {
    flex-basis: 0;
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    padding: 0 16px;
}

.ModalPageHeader__after {
    justify-content: flex-end;
}

.ModalPageHeader__before {
    justify-content: flex-start;
}
