@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url('inter/Inter-Light.woff2') format('woff2'),
  url('inter/Inter-Light.woff') format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url('inter/Inter-Regular.woff2') format('woff2'),
  url('inter/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url('inter/Inter-Medium.woff2') format('woff2'),
  url('inter/Inter-Medium.woff') format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url('inter/Inter-SemiBold.woff2') format('woff2'),
  url('inter/Inter-SemiBold.woff') format('woff');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url('inter/Inter-Bold.woff2') format('woff2'),
  url('inter/Inter-Bold.woff') format('woff');
}