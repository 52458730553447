.Placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 8px;
    padding: 48px 32px;
}

.Placeholder__actions {
    margin-top: 16px;
}