.Subtitle {
    display: block;
    font-family: var(--font_family_accent);
    font-weight: 700;
    line-height: 1.2em;
    color: var(--text_primary);
}

.Subtitle_level-1 {
    font-size: 21px;
}

.Subtitle_level-2 {
    font-size: 17px;
}