.PageHeader {
    position: fixed;
    top: 0;
    left: 0;
    background: var(--background_primary);
    display: flex;
    justify-content: space-between;
    padding: 0 var(--horizontal_padding);
    height: var(--pageHeader_height);
    width: 100%;
}

.PageHeader__nav {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-self: stretch;
}

.PageHeader__logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 256px;
}

.PageHeader__logo {
    height: 18px;
}

.PageHeader__buttons {
    display: flex;
    align-items: center;
    gap: 16px;
}

@media screen and (max-width: 1199px) {
    .PageHeader__nav, .PageHeader__buttons {
        display: none;
    }

    .PageHeader__logoContainer {
        width: auto;
    }

    .PageHeader__logo {
        height: 16px;
    }
}