@font-face {
  font-family: Bellota;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url('bellota/Bellota-Regular.woff2') format('woff2'),
  url('bellota/Bellota-Regular.woff') format('woff');
}

@font-face {
  font-family: Bellota;
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url('bellota/Bellota-Bold.woff2') format('woff2'),
  url('bellota/Bellota-Bold.woff') format('woff');
}