.ModalRoot {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
}

.ModalRoot.ModalRoot-active {
    pointer-events: all;
}

.ModalRoot__Modal {
    height: 100%;
}

.ModalRoot__Modal--shown .ModalRoot__darken {
    opacity: 1;
}


.ModalRoot__darken {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background_overlay);
    transition: opacity 167ms ease-out;
    opacity: 0;
}

body > .ModalRoot-active ~ .pageLayout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}