.Header {
    margin-bottom: 16px;
}

.Header__subheader {
    margin-top: 8px;
}

.Header__content {
    display: flex;
    align-items: center;
    gap: 12px;
}