.FormItem {
    padding: 12px 0;
}

.FormItem__label {
    font-size: 15px;
    margin-bottom: 8px;
    color: var(--text_tertiary);
}

.FormItem__message {
    margin-top: 8px;
}

.FormItem--status-default .FormField__border {
    border-color: var(--border_primary);
}

.FormItem--status-error .FormField__border {
    border-color: var(--negative);
    background-color: var(--negative_secondary);
}

.FormItem--status-error .FormItem__message {
    color: var(--negative);
}