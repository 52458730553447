.ModalPage__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ModalPage {
    opacity: 0;
    border-radius: 18px;
    background: var(--background_primary);
    width: 100%;
    max-width: 634px;
    max-height: calc(100% - 90px * 2);
    margin: 90px;
    transition: opacity 320ms cubic-bezier(0.36, 0.66, 0.04, 1);
    z-index: 11;
}

.ModalRoot__Modal--shown .ModalPage {
    opacity: 1;
}

.ModalPage--fullScreen {
    height: 100%;
}

.ModalPage__in {
    border-radius: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.ModalPage__content {
    height: 100%;
    overflow-y: scroll;
}

.ModalPage__content_in > .Group {
    padding: 8px 0;
}

.ModalPage__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px var(--horizontal_padding);
    background-color: var(--background_primary);
}

@media screen and (max-width: 767px) {
    .ModalPage__wrapper {
        align-items: flex-end;
    }

    .ModalPage {
        max-height: calc(100% - 48px);
        opacity: 1;
        margin: 0;
        border-radius: 18px 18px 0 0;
        transition: transform 320ms cubic-bezier(0.36, 0.66, 0.04, 1);
        transform: translateY(100%);
    }

    .ModalRoot__Modal--shown .ModalPage {
        transform: translateZ(0);
    }

    .ModalPage__content > .Group {
        padding: 8px 0;
    }
}