.NavItem {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px 20px;
    color: var(--text_secondary);
}

.NavItem__icon {
    width: 24px;
    height: 24px;
    color: var(--icon_secondary);
}

.NavItem-active {
    position: relative;
    background: var(--accent_tertiary);
    color: var(--accent);
}

.NavItem-active::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1.5px;
    background: var(--accent);
}

.NavItem-active > .NavItem__icon {
    color: inherit;
}