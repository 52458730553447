.Button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    gap: 8px;
}

.Button--stretched {
    width: 100%;
}

.Button__size-s {
    padding: 8px 16px;
}

.Button__size-m {
    padding: 10px 20px;
}

.Button__size-l {
    padding: 12px 24px;
}

.Button__appearance-accent.Button__mode-primary {
    --background: var(--accent);
    --color: var(--text_overlay);
}

.Button__appearance-accent.Button__mode-outline {
    --color: var(--accent);
    --border: var(--accent);
}

.Button__appearance-neutral.Button__mode-outline {
    --color: var(--text_tertiary);
    --border: var(--border_primary);
}

.Button__mode-outline {
    background: none;
    color: var(--color);
    box-shadow: inset 0 0 0 1px var(--border);
}

.Button__mode-primary {
    background: var(--background);
    color: var(--color);
}

.Button__mode-link {
    background: none;
    color: var(--accent);
}