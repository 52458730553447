.Body {
    display: block;
    font-family: var(--font_family_base);
    font-weight: 400;
}

.Body_level-1 {
    font-size: 16px;
    line-height: 1.25em;
}

.Body_level-2 {
    font-size: 15px;
    line-height: 1.2em;
}

.Body_weight-regular {
    font-weight: 400;
}

.Body_weight-medium {
    font-weight: 500;
}